import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import './enterprise-grade.css'
function EnterpriseGrade() {
    return (
        <div className='enterprise-grade-security' id="security">
            <h2>Enterprise-Grade <span className='black-text'>Security</span></h2>
            <p className='content'>We are SOC 2 Type II compliant. We follow the highest practices of coding, security, and PII protection.</p>
            <div className='flex justify-center'>
                <div className="logo">
                    <StaticImage
                        src={'../../assets/common/aicpa.png'}
                        alt={'aicpa logo'}
                        width={119}
                        height={134}
                        placeholder="none"
                        loading="lazy"
                        layout="fixed"
                    />
                </div>
                <div className="logo">
                    <StaticImage
                        src={'../../assets/common/hipaa.png'}
                        alt={'hipaa logo'}
                        width={119}
                        height={134}
                        placeholder="none"
                        loading="lazy"
                        layout="fixed"
                    />
                </div>
            </div>
        </div>
    )
}

export default EnterpriseGrade