import React from 'react'
import { howItworksType } from '../../../templates/use-cases';
import './how-it-works.css'
// import { GatsbyImage } from 'gatsby-plugin-image';
import unifiedImage from '../../../assets/use-cases/unified-search.svg';
import edgeAutomationImage from '../../../assets/use-cases/edge-automation.svg';
import analyticsImage from '../../../assets/use-cases/analytics.svg';

// temporaryly using img bcoz the image looks pixalated when using png/svg in StaticImage component even after setting quality high and
const AppImage = ({ image }: { image: string }) => {
    return <img src={image} alt="" style={{ width: "100%" }} loading="lazy" />
}

function HowItWorks({ howItWorksImages }: { howItWorksImages?: howItworksType }) {
    return (
        <div className='howitworks'>
            <h2 className='bold text-h2 h2-custom  text-align-center'>
                How does it work?
            </h2>
            <div className='parent-wrapper'>
                <div className='content-container'>
                    <div>
                        <p className='number'>01</p>
                        <h2 className='text-h2 h2-custom  title'>
                            {/* GPT Answer Engine */}
                            AI Answer Engine
                        </h2>
                        <p className='text-lg-new'>
                            {/* Answers powered by Generative AI connect disparate systems, including knowledge bases, ticketing platforms, project trackers, and social channels, to bring the most relevant answers.  Available via a web interface or embedded in common platforms such as Salesforce, Zendesk, and ServiceNow.  AptEdge's proprietary machine learning brings the industry's most accurate results in real time. */}
                            Answers powered by Generative AI connect disparate systems, including knowledge bases, ticketing platforms, project trackers, and social channels, to bring the most relevant answers.  Available via a web interface or embedded in common platforms such as Salesforce and Zendesk.  AptEdge's proprietary machine learning brings the industry's most accurate results in real time.
                        </p>
                    </div>
                    <div className='image-block'>
                        <AppImage image={unifiedImage} />
                        {/* <GatsbyImage alt={''} image={howItWorksImages.unified} style={{width: "100%"}} /> */}
                    </div>
                </div>
                <div className='content-container isreversed'>
                    <div>
                        <p className='number'>02</p>
                        <h2 className='text-h2 h2-custom  title'>
                            Edge Automation
                        </h2>
                        <p className='text-lg-new'>
                            Edge Automation identifies common trends in support cases and tickets linked into resolutions.  Using Edge Automation, a support team can lower ticket/support volumes by grouping common tickets for immediate deflection and reduce escalations by assigning tickets to the right team members.  Additionally, product team and executives gain key insights into trends impacting CSAT and feature requests.
                            {/* AptEdge Edge Automation identifies common trends in support cases and tickets linked into resolutions.  Using Edge Automation, a support team can lower ticket/support volumes by grouping common tickets for immediate deflection and reduce escalations by assigning tickets to the right team members.  Additionally, product team and executives gain key insights into trends impacting CSAT and feature requests. */}
                        </p>
                    </div>
                    <div className='image-block'>
                        <AppImage image={edgeAutomationImage} />
                        {/* <GatsbyImage alt={''} image={howItWorksImages.edgeAutomation } style={{width: "100%"}} /> */}
                    </div>
                </div>
                <div className='content-container'>
                    <div>
                        <p className='number'>03</p>
                        <h2 className='text-h2 h2-custom  title'>
                            Analytics
                        </h2>
                        <p className='text-lg-new'>
                            AptEdge Analytics provides a holistic view of the health of the support environment by pulling in knowledge sources from multiple platforms.  Understand themes and trends by customer, product, or ticket type over time to drive efficiency and key business insights.
                        </p>
                    </div>
                    <div className='image-block'>
                        <AppImage image={analyticsImage} />
                        {/* <GatsbyImage alt={''} image={howItWorksImages.analytic } style={{width: "100%"}} /> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HowItWorks;