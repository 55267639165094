import { graphql } from 'gatsby';
import React from 'react'
import AppLayout from '../../components/AppLayout';
import EnterpriseGrade from '../../components/EnterpriseGrade';
import PopularIntegrations from '../../components/popular-integrations';
import CtaBlock from '../../components/components-group-one/CtaBlock';
import HeroSection from '../../components/components-group-one/hero-section';
import HowItWorks from '../../components/components-group-one/how-it-works';
import Reasons from '../../components/components-group-one/reason';
import TestimonialBlock from '../../components/components-group-one/TestimonialBlock';
import { imageDataType } from '../prevent-escalations';

function Technology({ data }: { data: imageDataType }) {
  const pageData = {
    seoData: {
      title: "AI-Driven Knowledge Orchestration Platform with Generative AI ",
      canonicalLink: "https://www.aptedge.io/technology",
      metaDescription: {
        metaDescription: "Optimize Customer support with AptEdge: AI-driven GPT Answer Engine, Edge Automation, and Analytics for seamless Experience. Easily integrate with popular platforms.",
      },
      openGraphImage: {
        file: {
          url: "",
        },
      },
    },
    title: "Platform",
    description: "Make Knowledge Actionable for Customer Care",
    isTechnology: true,
    heroImage: data.heroImg.childImageSharp.gatsbyImageData,

    reasons: {
      title: "AI-Driven Knowledge Orchestration",
      subTitle: "Powered by Generative AI",
      // subTitle: "Powered by Generative AI (GPT)",
      content: "AptEdge is using its proprietary AI and workflow to orchestrate the disparate knowledge systems used by customer care, IT support, and service desk teams to activate knowledge through search and automation.",
      image: data.reasonImg.childImageSharp.gatsbyImageData,
    },
    testimonial: [
      {
        company: "everbridge",
        name: "Renee Bastine",
        bio: "Sr. Director, Global Customer Support",
        logo: data.testimonialLogoOne.childImageSharp.gatsbyImageData,
        content: "With AptEdge, we’re resolving tickets faster, reducing escalations, and achieving 99% CSAT."
      },
      {
        company: "cloud bees",
        name: "Ryan Smith",
        bio: "Sr. Support Manager",
        logo: data.testimonialLogoTwo.childImageSharp.gatsbyImageData,
        content: "AptEdge has been key in bridging silos between tools we use on a daily basis to build software."
      },
    ],
    dummyImg: data.dummyImg.childImageSharp.gatsbyImageData,
  }
  return (
    <AppLayout seoData={pageData.seoData}>
      <HeroSection
        title={pageData.title}
        heroImage={pageData.heroImage}
        description={pageData.description}
        isTechnology={pageData.isTechnology}
      />
      <Reasons reasons={pageData.reasons} />
      <HowItWorks />
      <PopularIntegrations />
      <TestimonialBlock testimonialData={pageData.testimonial} />
      <EnterpriseGrade />
      <CtaBlock />
    </AppLayout>
  )
}

export default Technology;

export const query = graphql`
    query TechnologyHomeQuery {
        reasonImg: file(relativePath: { eq: "technology/generative-ai.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: NONE)
            }
        }
        testimonialLogoOne: file(relativePath: { eq: "utkarsh/logo-everbridge.png" }) {
          childImageSharp {
              gatsbyImageData(placeholder: NONE)
          }
      }
      testimonialLogoTwo: file(relativePath: { eq: "utkarsh/logo-cloudbees.png" }) {
        childImageSharp {
            gatsbyImageData(placeholder: NONE)
        }
    }
        dummyImg: file(relativePath: { eq: "use-cases/dummy.png" }) {
          childImageSharp {
            gatsbyImageData(placeholder: NONE)
        }
      }
        heroImg: file(relativePath: { eq: "technology/technology-home-hero.png" }) {
          childImageSharp {
            gatsbyImageData(placeholder: NONE )
        }
        }
      }`