import React from 'react'
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import './popular-integrations.css'
import salesForce from "../../assets/common/integrations/sales-force.svg"
import khoros from "../../assets/common/integrations/khoros.svg"
import oneDrive from "../../assets/common/integrations/one-drive.svg"
import skillJar from "../../assets/common/integrations/skill-jar.svg"
import notion from "../../assets/common/integrations/notion.png"
import confluence from "../../assets/common/integrations/confluence.svg"


function PopularIntegrations() {
 
  const sliderLogos = [
    notion, confluence, salesForce, khoros, oneDrive, skillJar, notion, confluence, salesForce, khoros,
  ]
  // const sliderLogosSecond = [
  //   salesForce, khoros, oneDrive, skillJar, notion, confluence, salesForce, khoros, oneDrive, skillJar,
  // ];

  const sliderParams = (moveto: number) => {
    return {
      centered: true,
      breakpoints: {
          "(min-width: 640px)": {
              slidesPerView: 3,
          },
          "(min-width: 940px)": {
              slidesPerView: 6.5,
          },
        },
      slidesPerView: 2,
      loop: true,
      spacing: 25,
      mode: "free-snap",
      created: (slider: { moveToSlide: (arg0: number) => void; }) => {
        slider.moveToSlide(moveto)
      },
      slideChanged: (slider: { moveToSlide: (arg0: any, arg1: number) => void; details: () => { (): any; new(): any; absoluteSlide: number; }; }) => {
          slider.moveToSlide(slider.details().absoluteSlide + moveto, 70000)
      }
    }
  }

//@ts-ignore
  const [sliderRef] = useKeenSlider<HTMLDivElement>(sliderParams(5));
//@ts-ignore
  // const [secondSliderRef] = useKeenSlider<HTMLDivElement>(sliderParams(4));
  

  return (
    <div className='popular-integrations'>
      <h2 className='bold text-h2 text-align-center'>Our Most Popular Integrations</h2>

      <div className="keen-slider slider-container" ref={sliderRef}>
        {sliderLogos.map(each => 
           <div className="keen-slider__slide logo-container">
            <img src={each} alt="" width="180" height="53" loading='lazy' style={{objectFit:"contain"}} />
          </div>
        )}
      </div>
      
      {/* <div className="keen-slider slider-container" ref={secondSliderRef}>
        {sliderLogosSecond.map(each => 
           <div className="keen-slider__slide border-red logo-container">
            <img src={each} alt="" width="180" height="53" loading='lazy' />
          </div>
        )}
        </div> */}
    </div>
  )
}

export default PopularIntegrations